import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoDialog = _resolveComponent("InfoDialog")!

  return (_openBlock(), _createBlock(_component_InfoDialog, {
    title: "Дебаг инфо",
    ref: "dialog"
  }, {
    infoForm: _withCtx(({}) => [
      _createElementVNode("div", null, _toDisplayString(_ctx.info), 1)
    ]),
    _: 1
  }, 512))
}